<template>
  <page-loader :loading="fetching">
    <b-overlay :show="loading">
      <div
        ref="simulationWrap"
        class="simulation-wrap"
        :style="{
          height: `${wrapHeight}px`,
        }"
      >
        <template v-if="localSimulation">
          <div class="top-content">
            <b-card no-body class="d-flex flex-row">
              <div class="d-flex w-50 h-100 border-right">
                <div class="d-flex w-100 h-100 flex-column">
                  <span class="w-100 text-center">{{
                    T("Web.Generic.Simulations.BaseLine")
                  }}</span>
                  <div class="w-100 h-100 d-flex">
                    <div
                      style="min-width: 300px; min-height: 300px"
                      class="w-50 h-100"
                    >
                      <pie-chart
                        :title="baselineTonsPriceChartTitle"
                        :dataItems="baselineTonsPriceChart"
                        :subTitle="
                          T(
                            'Web.SimluationsPage.WasteDistributionSimulation.TonsPriceChartTitle',
                            'Avg. tons price'
                          )
                        "
                      ></pie-chart>
                    </div>
                    <div
                      style="min-width: 300px; min-height: 300px"
                      class="w-50 h-100"
                    >
                      <pie-chart
                        :title="baselineTonsCo2Title"
                        :dataItems="baselineTonsCo2Chart"
                        :subTitle="
                          T(
                            'Web.SimluationsPage.WasteDistributionSimulation.TonsCO2ChartTitle',
                            'Total tons Co2'
                          )
                        "
                      ></pie-chart>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex w-50 h-100">
                <div class="d-flex w-100 h-100 flex-column">
                  <span class="w-100 text-center">{{
                    T("Web.Generic.Simulations.BaseLine")
                  }}</span>
                  <div class="w-100 h-100 d-flex">
                    <div
                      style="min-width: 300px; min-height: 300px"
                      class="w-50 h-100"
                    >
                      <pie-chart
                        :title="simulatedTonsPriceChartTitle"
                        :dataItems="simulatedTonsPriceChart"
                        :subTitle="
                          T(
                            'Web.SimluationsPage.WasteDistributionSimulation.TonsPriceChartTitle',
                            'Avg. tons price'
                          )
                        "
                      ></pie-chart>
                    </div>
                    <div
                      style="min-width: 300px; min-height: 300px"
                      class="w-50 h-100"
                    >
                      <pie-chart
                        :title="simulatedTonsCo2Title"
                        :dataItems="simulatedTonsCo2Chart"
                        :subTitle="
                          T(
                            'Web.SimluationsPage.WasteDistributionSimulation.TonsCO2ChartTitle',
                            'Total tons Co2'
                          )
                        "
                      ></pie-chart>
                    </div>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
          <div class="bottom-content">
            <b-card no-body>
              <div class="p-2 d-flex justify-content-between">
                <simulation-title-edit
                  v-model="localSimulation.title"
                  @change="exeuteSimulationUpdate"
                />
                <div
                  class="d-flex justify-content-between d-flex align-items-center w-100"
                >
                  <span class="d-flex align-items-center w-50 pr-1">
                    <feather-icon icon="CalendarIcon" class="mr-1" size="20" />
                    <date-range-picker
                      ref="picker"
                      v-model="dateRange"
                      opens="left"
                      :locale-data="{ firstDay: 1, format: 'dd/mm/yyyy' }"
                      :append-to-body="true"
                      @update="refreshSimulation"
                    >
                    </date-range-picker>
                  </span>
                  <b-form-select
                    class="w-50"
                    v-model="localSimulation.locationScopeId"
                    @change="exeuteSimulationUpdate"
                  >
                    <b-form-select-option :value="null">{{
                      T("Web.Generic.Locations.AllLocations", "All locations")
                    }}</b-form-select-option>
                    <b-form-select-option
                      v-for="location in locations"
                      :key="location.id"
                      :value="location.id"
                      >{{ location.name }}</b-form-select-option
                    >
                  </b-form-select>
                  <loader class="loader ml-1" ref="loader" />
                </div>
              </div>
              <b-card-title
                v-if="
                  totalSimulatedShareSum != 0 &&
                  (totalSimulatedShareSum > 100 || totalSimulatedShareSum < 100)
                "
                class="d-flex align-items-center pl-2"
              >
                <span class="text-warning mr-1">{{
                  totalSimulatedShareSum > 100
                    ? T(
                        "Web.SimluationsPage.DistributionSimulator.ForcastShareWarrningMax",
                        "Your simulated share is over 100%"
                      )
                    : T(
                        "Web.SimluationsPage.DistributionSimulator.ForcastShareWarrningMin",
                        "Your simulated share is under 100%"
                      )
                }}</span>

                <feather-icon
                  color="#ff9f43"
                  size="20"
                  icon="AlertTriangleIcon"
                />
              </b-card-title>
              <div class="table-container">
                <b-table-simple class="simulationData" small responsive>
                  <b-thead>
                    <b-th colspan="2">{{ T("Web.Generic.Category") }}</b-th>
                    <b-th>{{
                      T(
                        "Web.SimluationsPage.DistributionSimulator.Share",
                        "Share"
                      )
                    }}</b-th>
                    <b-th>{{
                      T(
                        "Web.SimluationsPage.DistributionSimulator.PricePrTons",
                        "Price/tons"
                      )
                    }}</b-th>
                    <b-th>{{
                      T(
                        "Web.SimluationsPage.DistributionSimulator.Co2PrTons",
                        "Co2/tons"
                      )
                    }}</b-th>
                  </b-thead>
                  <b-tbody>
                    <!-- Without category -->
                    <b-tr>
                      <b-th rowspan="2" width="200">
                        {{
                          T(
                            "Web.Generic.PostingCategories.WithoutCategory",
                            "Without category"
                          )
                        }}</b-th
                      >
                      <b-th class="text-right" width="200">
                        <small>{{
                          T("Web.Generic.Simulations.BaseLine")
                        }}</small>
                      </b-th>
                      <b-td class="text-center d-flex flex-column">
                        <strong
                          >{{
                            localSimulation.withoutCategoryShare | toCurrency
                          }}
                        </strong>
                        <small>
                          ({{
                            localSimulation.withoutCategoryTons | toCurrency
                          }}
                          {{ T("Web.Generic.Units.Tons") }})
                        </small>
                      </b-td>
                      <b-td class="text-center">
                        <small>
                          {{
                            localSimulation.withoutCategoryPrice | toCurrency
                          }}
                        </small>
                      </b-td>
                      <b-td class="text-center">
                        <small>
                          {{
                              localSimulation.withoutCategoryCo2,
                            | toCurrency
                          }}
                        </small>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th class="text-right" width="200">
                        <small>{{
                          T("Web.Generic.Simulations.Simulated")
                        }}</small>
                      </b-th>
                      <b-td class="text-center d-flex flex-column">
                        <tailing-zero-number-input
                          @change="exeuteSimulationUpdate"
                          class="w-100"
                          v-model="
                            localSimulation.withoutCategoryShareSimulated
                          "
                        />
                        <small
                          >({{ withoutCategoryItem.tons | toCurrency }}
                          {{ T("Web.Generic.Units.Tons") }})</small
                        >
                      </b-td>
                      <b-td class="text-center"
                        ><strong>{{
                          withoutCategoryItem.price | toCurrency
                        }}</strong></b-td
                      >
                      <b-td class="text-center"
                        ><strong>{{
                          withoutCategoryItem.co2 | toCurrency
                        }}</strong></b-td
                      >
                    </b-tr>
                    <!-- Without category end -->
                    <!-- Reuse -->
                    <b-tr>
                      <b-th rowspan="2" width="200" class="table-reuse">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="12" cy="12" r="12" fill="#438651" />
                          <path
                            d="M16.2177 13.2099L16.8544 7.34195C16.9268 6.67428 16.3336 6.12642 15.6738 6.2516L10.2192 7.28644C6.15926 8.05669 4.69404 13.1934 7.31485 16.21C8.04194 14.5733 9.56351 12.242 11.7998 10.6437C9.8016 14.487 9.13874 16.0096 8.93893 17.4813C12.0015 19.0249 15.8083 16.9832 16.2177 13.2099Z"
                            fill="white"
                          />
                        </svg>
                        <span>{{
                          T("Web.Generic.WasteCategories.Reuse")
                        }}</span>
                      </b-th>
                      <b-th class="text-right table-reuse" width="200"
                        ><small>{{
                          T("Web.Generic.Simulations.BaseLine")
                        }}</small></b-th
                      >
                      <b-td class="text-center d-flex flex-column">
                        <strong>{{
                          localSimulation.reuseShare | toCurrency
                        }}</strong>
                        <small>
                          ({{ localSimulation.reuseTons | toCurrency }}
                          {{ T("Web.Generic.Units.Tons") }})
                        </small>
                      </b-td>
                      <b-td class="text-center">
                        <small
                          >{{ localSimulation.reusePrice | toCurrency }}
                        </small>
                      </b-td>
                      <b-td class="text-center"
                        ><small>{{
                          localSimulation.reuseCo2 | toCurrency
                        }}</small></b-td
                      >
                    </b-tr>
                    <b-tr>
                      <b-th class="text-right table-reuse" width="200"
                        ><small>{{
                          T("Web.Generic.Simulations.Simulated")
                        }}</small></b-th
                      >
                      <b-td class="text-center d-flex flex-column"
                        ><tailing-zero-number-input
                          @change="exeuteSimulationUpdate"
                          class="w-100"
                          v-model="localSimulation.reuseShareSimulated"
                        />
                        <small
                          >({{ reuseSimulatedItem.tons | toCurrency }}
                          {{ T("Web.Generic.Units.Tons") }})</small
                        ></b-td
                      >
                      <b-td class="text-center"
                        ><strong>{{
                          reuseSimulatedItem.price | toCurrency
                        }}</strong></b-td
                      >
                      <b-td class="text-center"
                        ><strong>{{
                          reuseSimulatedItem.co2 | toCurrency
                        }}</strong></b-td
                      >
                    </b-tr>
                    <!-- Reuse end -->
                    <!-- Recycle -->
                    <b-tr>
                      <b-th rowspan="2" width="200" class="table-recycle">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="12" cy="12" r="12" fill="#3C7A49" />
                          <path
                            d="M13.9695 10.7258H16.9999V7.73099"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7 8.60812C7.51591 8.09828 8.12838 7.69386 8.80244 7.41794C9.47651 7.14201 10.199 7 10.9286 7C11.6582 7 12.3806 7.14201 13.0547 7.41794C13.7288 7.69386 14.3412 8.09828 14.8571 8.60812L17 10.7258"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10.0305 13.2551H7V16.2499"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M17 15.3728C16.4841 15.8826 15.8716 16.2871 15.1976 16.563C14.5235 16.8389 13.801 16.9809 13.0714 16.9809C12.3418 16.9809 11.6194 16.8389 10.9453 16.563C10.2712 16.2871 9.65876 15.8826 9.14286 15.3728L7 13.2552"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <span>{{
                          T("Web.Generic.WasteCategories.Rezycl")
                        }}</span>
                      </b-th>
                      <b-th class="text-right table-recycle" width="200"
                        ><small>{{
                          T("Web.Generic.Simulations.BaseLine")
                        }}</small></b-th
                      >
                      <b-td class="text-center d-flex flex-column">
                        <strong>{{
                          localSimulation.recycleShare | toCurrency
                        }}</strong>
                        <small>
                          ({{ localSimulation.recycleTons | toCurrency }}
                          {{ T("Web.Generic.Units.Tons") }})
                        </small>
                      </b-td>
                      <b-td class="text-center">
                        <small>
                          {{ localSimulation.recyclePrice | toCurrency }}
                        </small>
                      </b-td>
                      <b-td class="text-center"
                        ><small>{{
                          localSimulation.recycleCo2 | toCurrency
                        }}</small></b-td
                      >
                    </b-tr>
                    <b-tr>
                      <b-th class="text-right table-recycle" width="200"
                        ><small>{{
                          T("Web.Generic.Simulations.Simulated")
                        }}</small></b-th
                      >
                      <b-td class="text-center d-flex flex-column"
                        ><tailing-zero-number-input
                          @change="exeuteSimulationUpdate"
                          class="w-100"
                          v-model="localSimulation.recycleShareSimulated"
                        />
                        <small
                          >({{ recycleSimulatedItem.tons | toCurrency }}
                          {{ T("Web.Generic.Units.Tons") }})</small
                        >
                      </b-td>
                      <b-td class="text-center"
                        ><strong>{{
                          recycleSimulatedItem.price | toCurrency
                        }}</strong></b-td
                      >
                      <b-td class="text-center"
                        ><strong>{{
                          recycleSimulatedItem.co2 | toCurrency
                        }}</strong></b-td
                      >
                    </b-tr>
                    <!-- Recycle end-->
                    <!-- Incineration -->
                    <b-tr>
                      <b-th rowspan="2" width="200" class="table-combostion">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="12" cy="12" r="12" fill="#305E38" />
                          <path
                            d="M10.5 10.8L8.34886 9.14263C7.58199 10.3458 7 11.7647 7 13.3C7 14.6261 7.52678 15.8978 8.46447 16.8355C9.40215 17.7732 10.6739 18.3 12 18.3C13.3261 18.3 14.5979 17.7732 15.5355 16.8355C16.4732 15.8978 17 14.6261 17 13.3C17 8.79999 12.5 5.29999 12.5 5.29999L10.5 10.8Z"
                            fill="white"
                          />
                        </svg>
                        <span>
                          {{
                            T("Web.Generic.WasteCategories.Combostion")
                          }}</span
                        >
                      </b-th>
                      <b-th class="text-right table-combostion" width="200"
                        ><small>{{
                          T("Web.Generic.Simulations.BaseLine")
                        }}</small></b-th
                      >
                      <b-td class="text-center d-flex flex-column">
                        <strong>{{
                          localSimulation.combostionShare | toCurrency
                        }}</strong>
                        <small>
                          ({{ localSimulation.combostionTons | toCurrency }}
                          {{ T("Web.Generic.Units.Tons") }})
                        </small>
                      </b-td>
                      <b-td class="text-center">
                        <small
                          >{{ localSimulation.combostionPrice | toCurrency }}
                        </small>
                      </b-td>
                      <b-td class="text-center"
                        ><small>{{
                          localSimulation.combostionCo2 | toCurrency
                        }}</small></b-td
                      >
                    </b-tr>
                    <b-tr>
                      <b-th class="text-right table-combostion" width="200"
                        ><small>{{
                          T("Web.Generic.Simulations.Simulated")
                        }}</small></b-th
                      >
                      <b-td class="text-center d-flex flex-column"
                        ><tailing-zero-number-input
                          @change="exeuteSimulationUpdate"
                          class="w-100"
                          v-model="localSimulation.combustionShareSimulated"
                        />
                        <small
                          >({{ combustionSimulatedItem.tons | toCurrency }}
                          {{ T("Web.Generic.Units.Tons") }})</small
                        >
                      </b-td>
                      <b-td class="text-center"
                        ><strong>{{
                          combustionSimulatedItem.price | toCurrency
                        }}</strong></b-td
                      >
                      <b-td class="text-center"
                        ><strong>{{
                          combustionSimulatedItem.co2 | toCurrency
                        }}</strong></b-td
                      >
                    </b-tr>
                    <!-- Incineration end-->
                    <!-- Special -->
                    <b-tr>
                      <b-th rowspan="2" width="200" class="table-special">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="12" cy="12" r="12" fill="#234328" />
                          <path
                            d="M10 11H12V18"
                            stroke="white"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 8C12.8284 8 13.5 7.32843 13.5 6.5C13.5 5.67157 12.8284 5 12 5C11.1716 5 10.5 5.67157 10.5 6.5C10.5 7.32843 11.1716 8 12 8Z"
                            fill="white"
                          />
                        </svg>
                        <span>
                          {{ T("Web.Generic.WasteCategories.Special") }}</span
                        >
                      </b-th>
                      <b-th class="text-right table-special" width="200"
                        ><small>{{
                          T("Web.Generic.Simulations.BaseLine")
                        }}</small></b-th
                      >
                      <b-td class="text-center d-flex flex-column">
                        <strong>{{
                          localSimulation.specialShare | toCurrency
                        }}</strong>
                        <small>
                          ({{ localSimulation.specialTons | toCurrency }}
                          {{ T("Web.Generic.Units.Tons") }})
                        </small></b-td
                      >
                      <b-td class="text-center">
                        <small
                          >{{ localSimulation.specialPrice | toCurrency }}
                        </small>
                      </b-td>
                      <b-td class="text-center"
                        ><small>{{
                          localSimulation.specialCo2 | toCurrency
                        }}</small></b-td
                      >
                    </b-tr>
                    <b-tr>
                      <b-th class="text-right table-special" width="200"
                        ><small>{{
                          T("Web.Generic.Simulations.Simulated")
                        }}</small></b-th
                      >
                      <b-td class="text-center d-flex flex-column">
                        <tailing-zero-number-input
                          @change="exeuteSimulationUpdate"
                          class="w-100"
                          v-model="localSimulation.specialShareSimulated"
                        />
                        <small
                          >({{ specialSimulatedItem.tons | toCurrency }}
                          {{ T("Web.Generic.Units.Tons") }})</small
                        >
                      </b-td>
                      <b-td class="text-center"
                        ><strong>{{
                          specialSimulatedItem.price | toCurrency
                        }}</strong></b-td
                      >
                      <b-td class="text-center"
                        ><strong>{{
                          specialSimulatedItem.co2 | toCurrency
                        }}</strong></b-td
                      >
                    </b-tr>
                    <!-- Special end-->
                    <!-- Landfill -->
                    <b-tr>
                      <b-th rowspan="2" width="200" class="table-landfill">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="12" cy="12" r="12" fill="#16291A" />
                          <path
                            d="M17.9783 15C18.1817 15 18.2999 14.7702 18.1817 14.6047L13.6675 8.28481C13.5678 8.14522 13.3604 8.14522 13.2607 8.28481L11.4053 10.8824L12.4641 13L11.147 11.2439L11.1405 11.2531L9.66468 9.26958C9.56471 9.13522 9.36349 9.13522 9.26353 9.26958L5.29703 14.6008C5.17433 14.7657 5.29204 15 5.49761 15H17.9783Z"
                            fill="white"
                          />
                        </svg>
                        <span>
                          {{ T("Web.Generic.WasteCategories.Landfill") }}</span
                        >
                      </b-th>
                      <b-th class="text-right table-landfill" width="200"
                        ><small>{{
                          T("Web.Generic.Simulations.BaseLine")
                        }}</small></b-th
                      >
                      <b-td class="text-center d-flex flex-column">
                        <strong>{{
                          localSimulation.landfillShare | toCurrency
                        }}</strong>
                        <small>
                          ({{ localSimulation.landfillTons | toCurrency }}
                          {{ T("Web.Generic.Units.Tons") }})
                        </small>
                      </b-td>
                      <b-td class="text-center">
                        <small
                          >{{ localSimulation.landfillPrice | toCurrency }}
                        </small>
                      </b-td>
                      <b-td class="text-center"
                        ><small>{{
                          localSimulation.landfillCo2 | toCurrency
                        }}</small></b-td
                      >
                    </b-tr>
                    <b-tr>
                      <b-th class="text-right table-landfill" width="200"
                        ><small>{{
                          T("Web.Generic.Simulations.Simulated")
                        }}</small></b-th
                      >
                      <b-td class="text-center d-flex flex-column">
                        <tailing-zero-number-input
                          @change="exeuteSimulationUpdate"
                          class="w-100"
                          v-model="localSimulation.landfillShareSimulated"
                        />
                        <small
                          >({{ landfillSimulatedItem.tons | toCurrency }}
                          {{ T("Web.Generic.Units.Tons") }})</small
                        >
                      </b-td>
                      <b-td class="text-center"
                        ><strong>{{
                          landfillSimulatedItem.price | toCurrency
                        }}</strong></b-td
                      >
                      <b-td class="text-center"
                        ><strong>{{
                          landfillSimulatedItem.co2 | toCurrency
                        }}</strong></b-td
                      >
                    </b-tr>
                    <!-- Landfill end -->
                  </b-tbody>
                </b-table-simple>
              </div>
            </b-card>
          </div>
        </template>
      </div>
    </b-overlay>
  </page-loader>
</template>
<script>
import PageLoader from "@/app/components/layout/PageLoader.vue";
import {
  BOverlay,
  BCardTitle,
  BCard,
  BFormSelect,
  BFormSelectOption,
  BTableSimple,
  BTbody,
  BThead,
  BTh,
  BTr,
  BTd,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import SimulationTitleEdit from "../components/SimulationTitleEdit.vue";
import TailingZeroNumberInput from "@/app/components/layout/TailingZeroNumberInput.vue";
import PieChart from "@/components/charts/PieChart.vue";
import loader from "@/components/layout/loader.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

export default {
  components: {
    PageLoader,
    BOverlay,
    BCardTitle,
    BCard,
    SimulationTitleEdit,
    BThead,
    BFormSelect,
    BTableSimple,
    BFormSelectOption,
    loader,
    BTbody,
    BTh,
    BTr,
    BTd,
    TailingZeroNumberInput,
    PieChart,
    DateRangePicker,
  },
  data() {
    return {
      wrapHeight: 0,
      fetching: false,
      loading: false,
      noCatColor: "#ccc",
      reuseColor: "#438651",
      rezyclColor: "#3c7a49",
      combostionColor: "#305e38",
      specialColor: "#234328",
      landfillColor: "#16291a",
      dateRange: {
        startDate: moment(new Date()).subtract("year", 1),
        endDate: moment(new Date()),
      },
    };
  },
  async created() {
    await this.refreshSimulation();
  },
  mounted() {
    this.updateVhHeight();
    window.addEventListener("resize", () => this.updateVhHeight());
  },
  computed: {
    ...mapGetters({
      simulationById: "economics/simulations/wasteDistributionSimulations/byId",
      locations: "locations/list",
    }),
    totalSimulatedShareSum() {
      if (this.localSimulation == undefined) return 0;

      return (
        this.localSimulation.recycleShareSimulated +
        this.localSimulation.reuseShareSimulated +
        this.localSimulation.combustionShareSimulated +
        this.localSimulation.specialShareSimulated +
        this.localSimulation.landfillShareSimulated +
        this.localSimulation.withoutCategoryShareSimulated
      );
    },
    localSimulation() {
      let sim = this.simulationById(this.simulationId);

      sim.withoutCategoryExpenses = 0;
      return sim;
    },
    simulationId() {
      return this.$route.params.id;
    },
    baselineTonsPriceChartTitle() {
      if (this.localSimulation == undefined) return "";

      let result = this.safeDivide(
        this.localSimulation.totalExpenses,
        this.localSimulation.totalTons
      );
      return this.$options.filters.toCurrency(result);
    },
    baselineTonsPriceChart() {
      if (this.localSimulation == undefined) return [];

      return [
        new this.getChartItem(
          this.localSimulation.withoutCategoryExpenses,
          this.T("Web.Generic.WasteCategories.WithoutCategory"),
          this.noCatColor,
          "transparent"
        ),
        new this.getChartItem(
          this.localSimulation.reuseExpenses,
          this.T("Web.Generic.WasteCategories.Reuse"),
          this.reuseColor,
          require("@/assets/images/widget/reusesvg.png")
        ),
        new this.getChartItem(
          this.localSimulation.recycleExpenses,
          this.T("Web.Generic.WasteCategories.Rezycl"),
          this.rezyclColor,
          require("@/assets/images/widget/rezycl.png")
        ),
        new this.getChartItem(
          this.localSimulation.combostionExpenses,
          this.T("Web.Generic.WasteCategories.Combostion"),
          this.combostionColor,
          require("@/assets/images/widget/CombostionCategory.png")
        ),
        new this.getChartItem(
          this.localSimulation.specialExpenses,
          this.T("Web.Generic.WasteCategories.Special"),
          this.specialColor,
          require("@/assets/images/widget/SpecialCategory.png")
        ),
        new this.getChartItem(
          this.localSimulation.landfillExpenses,
          this.T("Web.Generic.WasteCategories.Landfill"),
          this.landfillColor,
          require("@/assets/images/widget/LandfillCategory.png")
        ),
      ];
    },
    simulatedTonsPriceChartTitle() {
      if (this.localSimulation == undefined) return "";

      let sum =
        this.withoutCategoryItem.expenses +
        this.reuseSimulatedItem.expenses +
        this.recycleSimulatedItem.expenses +
        this.combustionSimulatedItem.expenses +
        this.specialSimulatedItem.expenses +
        this.landfillSimulatedItem.expenses;

      let result = this.safeDivide(sum, this.localSimulation.totalTons);
      return this.$options.filters.toCurrency(result);
    },
    simulatedTonsPriceChart() {
      if (this.localSimulation == undefined) return [];

      return [
        new this.getChartItem(
          this.withoutCategoryItem.expenses,
          this.T("Web.Generic.WasteCategories.WithoutCategory"),
          this.noCatColor,
          "transparent"
        ),
        new this.getChartItem(
          this.reuseSimulatedItem.expenses,
          this.T("Web.Generic.WasteCategories.Reuse"),
          this.reuseColor,
          require("@/assets/images/widget/reusesvg.png")
        ),
        new this.getChartItem(
          this.recycleSimulatedItem.expenses,
          this.T("Web.Generic.WasteCategories.Rezycl"),
          this.rezyclColor,
          require("@/assets/images/widget/rezycl.png")
        ),
        new this.getChartItem(
          this.combustionSimulatedItem.expenses,
          this.T("Web.Generic.WasteCategories.Combostion"),
          this.combostionColor,
          require("@/assets/images/widget/CombostionCategory.png")
        ),
        new this.getChartItem(
          this.specialSimulatedItem.expenses,
          this.T("Web.Generic.WasteCategories.Special"),
          this.specialColor,
          require("@/assets/images/widget/SpecialCategory.png")
        ),
        new this.getChartItem(
          this.landfillSimulatedItem.expenses,
          this.T("Web.Generic.WasteCategories.Landfill"),
          this.landfillColor,
          require("@/assets/images/widget/LandfillCategory.png")
        ),
      ];
    },
    baselineTonsCo2Title() {
      if (this.localSimulation == undefined) return "";

      return this.$options.filters.toCurrency(this.localSimulation.totalCo2);
    },
    baselineTonsCo2Chart() {
      if (this.localSimulation == undefined) return [];
      return [
        new this.getChartItem(
          this.localSimulation.reuseCo2,
          this.T("Web.Generic.WasteCategories.Reuse"),
          this.reuseColor,
          require("@/assets/images/widget/reusesvg.png")
        ),
        new this.getChartItem(
          this.localSimulation.recycleCo2,
          this.T("Web.Generic.WasteCategories.Rezycl"),
          this.rezyclColor,
          require("@/assets/images/widget/rezycl.png")
        ),
        new this.getChartItem(
          this.localSimulation.combostionCo2,
          this.T("Web.Generic.WasteCategories.Combostion"),
          this.combostionColor,
          require("@/assets/images/widget/CombostionCategory.png")
        ),
        new this.getChartItem(
          this.localSimulation.specialCo2,
          this.T("Web.Generic.WasteCategories.Special"),
          this.specialColor,
          require("@/assets/images/widget/SpecialCategory.png")
        ),
        new this.getChartItem(
          this.localSimulation.landfillCo2,
          this.T("Web.Generic.WasteCategories.Landfill"),
          this.landfillColor,
          require("@/assets/images/widget/LandfillCategory.png")
        ),
      ];
    },
    simulatedTonsCo2Title() {
      if (this.localSimulation == undefined) return "";

      let totalSimulatedSum =
        this.reuseSimulatedItem.co2 +
        this.recycleSimulatedItem.co2 +
        this.combustionSimulatedItem.co2 +
        this.specialSimulatedItem.co2 +
        this.landfillSimulatedItem.co2;
      return this.$options.filters.toCurrency(totalSimulatedSum);
    },
    simulatedTonsCo2Chart() {
      if (this.localSimulation == undefined) return [];

      return [
        new this.getChartItem(
          this.reuseSimulatedItem.co2,
          this.T("Web.Generic.WasteCategories.Reuse"),
          this.reuseColor,
          require("@/assets/images/widget/reusesvg.png")
        ),
        new this.getChartItem(
          this.recycleSimulatedItem.co2,
          this.T("Web.Generic.WasteCategories.Rezycl"),
          this.rezyclColor,
          require("@/assets/images/widget/rezycl.png")
        ),
        new this.getChartItem(
          this.combustionSimulatedItem.co2,
          this.T("Web.Generic.WasteCategories.Combostion"),
          this.combostionColor,
          require("@/assets/images/widget/CombostionCategory.png")
        ),
        new this.getChartItem(
          this.specialSimulatedItem.co2,
          this.T("Web.Generic.WasteCategories.Special"),
          this.specialColor,
          require("@/assets/images/widget/SpecialCategory.png")
        ),
        new this.getChartItem(
          this.landfillSimulatedItem.co2,
          this.T("Web.Generic.WasteCategories.Landfill"),
          this.landfillColor,
          require("@/assets/images/widget/LandfillCategory.png")
        ),
      ];
    },
    withoutCategoryItem() {
      if (this.localSimulation == undefined) return {};

      return this.calculateSimulation(
        this.localSimulation.withoutCategoryShare,
        this.localSimulation.withoutCategoryShareSimulated,
        this.localSimulation.withoutCategoryPrice,
        this.localSimulation.withoutCategoryCo2,
        this.localSimulation.withoutCategoryTons,
        this.localSimulation.withoutCategoryExpenses
      );
    },
    landfillSimulatedItem() {
      if (this.localSimulation == undefined) return {};

      return this.calculateSimulation(
        this.localSimulation.landfillShare,
        this.localSimulation.landfillShareSimulated,
        this.localSimulation.landfillPrice,
        this.localSimulation.landfillCo2,
        this.localSimulation.landfillTons,
        this.localSimulation.landfillExpenses
      );
    },
    specialSimulatedItem() {
      if (this.localSimulation == undefined) return {};

      return this.calculateSimulation(
        this.localSimulation.specialShare,
        this.localSimulation.specialShareSimulated,
        this.localSimulation.specialPrice,
        this.localSimulation.specialCo2,
        this.localSimulation.specialTons,
        this.localSimulation.specialExpenses
      );
    },
    combustionSimulatedItem() {
      if (this.localSimulation == undefined) return {};

      return this.calculateSimulation(
        this.localSimulation.combostionShare,
        this.localSimulation.combustionShareSimulated,
        this.localSimulation.combostionPrice,
        this.localSimulation.combostionCo2,
        this.localSimulation.combostionTons,
        this.localSimulation.combostionExpenses
      );
    },
    reuseSimulatedItem() {
      if (this.localSimulation == undefined) return {};

      return this.calculateSimulation(
        this.localSimulation.reuseShare,
        this.localSimulation.reuseShareSimulated,
        this.localSimulation.reusePrice,
        this.localSimulation.reuseCo2,
        this.localSimulation.reuseTons,
        this.localSimulation.reuseExpenses
      );
    },
    recycleSimulatedItem() {
      if (this.localSimulation == undefined) return {};

      return this.calculateSimulation(
        this.localSimulation.recycleShare,
        this.localSimulation.recycleShareSimulated,
        this.localSimulation.recyclePrice,
        this.localSimulation.recycleCo2,
        this.localSimulation.recycleTons,
        this.localSimulation.recycleExpenses
      );
    },
  },
  methods: {
    ...mapActions({
      fetchSimulation:
        "economics/simulations/wasteDistributionSimulations/fetchSingle1",
      updateSimulation:
        "economics/simulations/wasteDistributionSimulations/update",
    }),
    async refreshSimulation() {
      console.log("H--->");
      await this.fetchSimulation({
        id: this.simulationId,
        fromDate: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
        toDate: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
      });
    },
    getChartItem(value, name, color, background, labelformatter = "") {
      let obj = {
        value: value,
        name: name,
        itemStyle: { color: color },
        label: {
          formatter: labelformatter,
          backgroundColor: {
            image: value > 0 ? background : "transparent",
          },
          width: 25,
          height: 25,
        },
      };

      return obj;
    },
    async exeuteSimulationUpdate() {
      this.$refs.loader.loading();
      await this.updateSimulation({ data: this.localSimulation });
      this.$refs.loader.loadCompelete();
    },
    updateVhHeight() {
      const positionFromTop =
        this.$refs.simulationWrap.getBoundingClientRect().top;
      this.wrapHeight = window.innerHeight - positionFromTop;
    },
    calculateSimulation(originalShare, newShare, price, co2, tons, expenses) {
      if (newShare == 0 || originalShare == 0)
        return { price: 0, co2: 0, expenses: 0, tons: 0 };

      let percentageDiff = newShare / originalShare;

      let t = this.safeDivide(expenses, tons);
      let newPrice = expenses * ((price * percentageDiff) / t);

      let newCo2 = co2 * percentageDiff;

      return {
        price: price,
        co2: newCo2,
        expenses: newPrice,
        tons: tons * percentageDiff,
      };
    },
    safeDivide(a, b) {
      return b > 0 ? a / b : 0;
    },
    calcPercentage(input, total) {
      if (input == 0 || total == 0) return 0;
      return (input / total) * 100;
    },
  },
};
</script>
<style lang="scss" scoped>
td {
  input {
    border: 0px;
    font-weight: bold;
    text-align: center;
    &:focus {
      outline: 0;
      background-color: #dcdddf;
    }
  }
}
.simulationData {
  margin-bottom: unset !important;
}
.simulation-wrap {
  height: 100%;
  position: relative;

  .top-content {
    overflow: hidden;
    height: 40%;
    padding-bottom: 20px;
  }

  .bottom-content {
    padding-bottom: 20px;
  }

  .card {
    height: 100%;
  }

  .table-reuse {
    background: #438651;
    color: white;
  }
  .table-recycle {
    background: #3c7a49;
    color: white;
  }
  .table-combostion {
    background: #305e38;
    color: white;
  }
  .table-special {
    background: #234328;
    color: white;
  }
  .table-landfill {
    background: #16291a;
    color: white;
  }
}
</style>